import * as Styled from "./EventDetailsModal.styles";

import { Accordion, Contact } from "../../components";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import {
  SIGNUP_USER_TO_EVENT,
  UNSIGNUP_USER_TO_EVENT,
} from "../../services/endpoints";
import {
  dateToUnix,
  getAmOrPm,
  getHourFromMilliseconds,
  getMinutesFromMilliseconds,
  getMonthAndDayFromMilliseconds,
  nthNumber,
  unixDateDiff,
} from "../../utils/utils";
import {
  getEvent,
  getEventSignups,
  refreshEvent,
  refreshEventSignups,
} from "../../store/actions/eventActions";
import { memo, useEffect, useState } from "react";

import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import BaseModal from "../BaseModal/BaseModal";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import EditIcon from "@mui/icons-material/Edit";
import EventSignUpsModal from "../EventSignUpsModal/EventSignUpsModal";
import { GatherDefaultImage } from "../../assets";
import LoginOutlinedIcon from "@mui/icons-material/LoginOutlined";
import MicrosoftIcon from "@mui/icons-material/Microsoft";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import { RootState } from "../../store/store";
import ShareEventModal from "../ShareEventModal/ShareEventModal";
import ShareIcon from "@mui/icons-material/Share";
import { SignUp } from "../../types/signup";
import SignUpSurveyModal from "../SignUpSurveyModal/SignUpSurveyModal";
import { SurveyAnswer } from "../../types/surveyquestions";
import axiosAPI from "../../services/axios";
import { createSelector } from "@reduxjs/toolkit";
import { getAllUserEvents } from "../../store/actions/userEventsActions";
import { useAppDispatch } from "../../store/hooks";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

type EventDetailsModalProps = {
  open: any;
  onClose: any;
  eventId: string;
};

const EventDetailsModal = memo(
  ({ open, onClose, eventId }: EventDetailsModalProps) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [signupUserLoading, setSignupUserLoading] = useState(false);
    const [unsignupUserLoading, setUnsignupUserLoading] = useState(false);
    const [showSignUpSurveyModal, setShowSignUpSurveyModal] = useState(false);
    const [showEventSignUpsModal, setShowEventSignupsModal] = useState(false);

    const [showQrCodeDetailsModal, setShowQrCodeDetailsModal] = useState(false);

    const selectUser = (state: RootState) => state.user;
    const selectSignupsLoading = (state: RootState) =>
      state.event.signups.loading;
    const selectSignups = (state: RootState) => state.event.signups.data;
    const selectEventLoading = (state: RootState) => state.event.loading;
    const selectEvent = (state: RootState) => state.event.data;

    const selectUserAndEvents = createSelector(
      [
        selectUser,
        selectSignupsLoading,
        selectSignups,
        selectEventLoading,
        selectEvent,
      ],
      (user, signupsLoading, signups, eventLoading, event) => {
        return { user, signupsLoading, signups, eventLoading, event };
      }
    );

    const { user, signups, signupsLoading, eventLoading, event } =
      useSelector(selectUserAndEvents);

    const isSignedUp = signups?.find(
      (signup) => signup.participantEmail === user.email
    );

    const signupUserToEvent = async (surveyAnswers?: SurveyAnswer[]) => {
      if (event) {
        setSignupUserLoading(true);
        const signupUserObj = {
          participantEmail: user.email,
          participantName: user.name,
          participantTitle: user.jobTitle,
          ...(surveyAnswers && { signUpSurveySubmission: surveyAnswers }),
        };
        await axiosAPI
          .post(SIGNUP_USER_TO_EVENT(event.eventId), signupUserObj)
          .then((response: any) => {
            setSignupUserLoading(false);
            dispatch(getEventSignups(eventId));
            dispatch(getAllUserEvents(user.email));
          })
          .catch((error) => {
            setSignupUserLoading(false);
          });
      }
    };

    const unsignUserToEvent = async () => {
      if (event) {
        setUnsignupUserLoading(true);
        const unsignupUserObj = {
          participantEmail: user.email,
        };
        await axiosAPI
          .post(UNSIGNUP_USER_TO_EVENT(event.eventId), unsignupUserObj)
          .then((response: any) => {
            setUnsignupUserLoading(false);
            dispatch(getEventSignups(eventId));
            dispatch(getAllUserEvents(user.email));
          })
          .catch((error) => {
            setUnsignupUserLoading(false);
          });
      }
    };

    const generateEventLink = () => {
      const url = new URL(window.location.href);
      const baseLink = `${url.protocol}//${url.host}`;
      return `${baseLink}/events?eventId=${eventId}`;
    };

    useEffect(() => {
      let intervalId: NodeJS.Timeout;

      if (eventId !== "" && open) {
        if (event && event.eventId === eventId) {
          dispatch(refreshEvent(eventId));
          dispatch(refreshEventSignups(eventId));
        } else {
          dispatch(getEvent(eventId));
          dispatch(getEventSignups(eventId));
        }

        intervalId = setInterval(() => {
          if (eventId !== "") {
            dispatch(refreshEvent(eventId));
            dispatch(refreshEventSignups(eventId));
          }
        }, 10000);
      }

      return () => {
        if (intervalId) {
          clearInterval(intervalId);
        }
      };

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventId, open]);

    const theme = useTheme();
    return (
      <BaseModal
        open={open}
        onClose={onClose}
        tabletWidth="80%"
        desktopWidth="940px"
        height="95vh"
        fullScreenMobile
      >
        {eventLoading || !event ? (
          <Stack
            direction="column"
            width="100%"
            height="100%"
            style={{
              marginTop: theme.spacing(3),
            }}
          >
            <Styled.ScrollableContent
              style={{
                overflow: "hidden",
              }}
            >
              <Styled.SkeletonHero variant="rectangular" />
              <Stack
                direction={{ mobile: "column", desktop: "row" }}
                width="100%"
                height="100%"
                spacing={{ mobile: 2, desktop: 2 }}
              >
                <Styled.SkeletonContent variant="rectangular" />
                <Styled.SkeletonContent variant="rectangular" />
              </Stack>
            </Styled.ScrollableContent>
            <Styled.ButtonsContainer>
              <Styled.SkeletonButton variant="rectangular" />
            </Styled.ButtonsContainer>
          </Stack>
        ) : (
          <Stack
            direction="column"
            width="100%"
            style={{ height: "100%", marginTop: theme.spacing(3) }}
          >
            <ShareEventModal
              link={generateEventLink()}
              open={showQrCodeDetailsModal}
              onClose={() => {
                setShowQrCodeDetailsModal(false);
              }}
            />
            {event.signUpSurvey && (
              <SignUpSurveyModal
                open={showSignUpSurveyModal}
                onClose={() => {
                  setShowSignUpSurveyModal(false);
                }}
                signUpSurvey={event.signUpSurvey}
                signUpUserCallback={signupUserToEvent}
              />
            )}
            <EventSignUpsModal
              open={showEventSignUpsModal}
              onClose={() => {
                setShowEventSignupsModal(false);
              }}
              eventId={eventId}
            />
            <Styled.ScrollableContent>
              <Styled.HeroContainer>
                <Styled.HeroBackground
                  style={{
                    backgroundImage: `url("${
                      event.eventHeroS3Url || GatherDefaultImage
                    }")`,
                    filter: event.eventHeroS3Url
                      ? "blur(50px) brightness(.9)"
                      : "",
                  }}
                ></Styled.HeroBackground>
                <Styled.HeroImage
                  src={event.eventHeroS3Url || GatherDefaultImage}
                  alt={event.title}
                  loading="lazy"
                />
              </Styled.HeroContainer>
              <Stack
                direction={{ mobile: "column", desktop: "row" }}
                width="100%"
                spacing={{ mobile: 0, desktop: 6 }}
              >
                <Styled.DetailsContainer>
                  <Box>
                    <Typography
                      variant="modalSubtitle"
                      style={{ marginBottom: theme.spacing(1) }}
                    >
                      Details
                    </Typography>
                    <Styled.ModalTitleText variant="h1">
                      {event.title}
                    </Styled.ModalTitleText>
                    <Styled.ModalBodyMB2
                      variant="modalBody"
                      style={{ whiteSpace: "pre-wrap" }}
                    >
                      {event.description}
                    </Styled.ModalBodyMB2>
                    <Styled.EventActionButtonContainer
                      onClick={() => {
                        setShowQrCodeDetailsModal(true);
                      }}
                    >
                      <ShareIcon color="primary" />
                      <Typography
                        variant="body2"
                        style={{
                          marginLeft: theme.spacing(1),
                          marginRight: theme.spacing(1),
                        }}
                      >
                        Share Event
                      </Typography>
                    </Styled.EventActionButtonContainer>

                    <Stack direction={"row"} width="100%">
                      <Box style={{ width: "50%" }}>
                        <Typography
                          variant="subtitle2"
                          style={{
                            color: theme.colors.neutral.darkGray,
                          }}
                        >
                          Date
                        </Typography>
                        <Styled.ModalBodyMB2 variant="modalBody">
                          {getMonthAndDayFromMilliseconds(event.startDateTime)}
                          {getMonthAndDayFromMilliseconds(
                            event.startDateTime
                          ) !== "Today" && (
                            <Styled.SuperScript>
                              {nthNumber(event.startDateTime)}
                            </Styled.SuperScript>
                          )}
                        </Styled.ModalBodyMB2>
                        <Typography
                          variant="subtitle2"
                          style={{
                            color: theme.colors.neutral.darkGray,
                          }}
                        >
                          Dress Code
                        </Typography>
                        <Styled.ModalBodyMB2 variant="modalBody">
                          {event.dressCode || "N/A"}
                        </Styled.ModalBodyMB2>
                        <Typography
                          variant="subtitle2"
                          style={{
                            color: theme.colors.neutral.darkGray,
                          }}
                        >
                          Format
                        </Typography>
                        <Styled.ModalBodyMB2 variant="modalBody">
                          {event.format}
                        </Styled.ModalBodyMB2>
                      </Box>
                      <Box style={{ width: "50%" }}>
                        <Typography
                          variant="subtitle2"
                          style={{
                            color: theme.colors.neutral.darkGray,
                          }}
                        >
                          Time
                        </Typography>
                        <Styled.ModalBodyMB2 variant="modalBody">
                          {`${getHourFromMilliseconds(event.startDateTime)}${
                            getMinutesFromMilliseconds(event.startDateTime) !==
                            "00"
                              ? `:${getMinutesFromMilliseconds(
                                  event.startDateTime
                                )}`
                              : ""
                          }`}
                          <Styled.SuperScript>
                            {getAmOrPm(event.startDateTime)}
                          </Styled.SuperScript>
                          {" - "}
                          {`${getHourFromMilliseconds(event.endDateTime)}${
                            getMinutesFromMilliseconds(event.endDateTime) !==
                            "00"
                              ? `:${getMinutesFromMilliseconds(
                                  event.endDateTime
                                )}`
                              : ""
                          }`}
                          <Styled.SuperScript>
                            {getAmOrPm(event.endDateTime)}
                          </Styled.SuperScript>
                        </Styled.ModalBodyMB2>
                        <Typography
                          variant="subtitle2"
                          style={{
                            color: theme.colors.neutral.darkGray,
                          }}
                        >
                          Cost
                        </Typography>
                        <Styled.ModalBodyMB2 variant="modalBody">
                          {event.admissionCost
                            ? `$${event.admissionCost.toString()}`
                            : "N/A"}
                        </Styled.ModalBodyMB2>

                        {event.teamsLink !== "" && (
                          <>
                            <Typography
                              variant="subtitle2"
                              style={{
                                color: theme.colors.neutral.darkGray,
                              }}
                            >
                              Teams Meeting Link
                            </Typography>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                cursor: "pointer",
                                transition: "opacity 0.3s ease",
                                "&:hover": {
                                  opacity: 0.7,
                                },
                              }}
                              onClick={() => {
                                window.open(event.teamsLink, "_blank");
                              }}
                            >
                              <MicrosoftIcon
                                color="primary"
                                sx={{ width: "14px", height: "14px" }}
                              />
                              <Typography
                                variant="modalBody"
                                style={{
                                  marginLeft: theme.spacing(1),
                                  marginRight: theme.spacing(1),
                                  color: theme.colors.primary.slalomBlue,
                                  textDecoration: "underline",
                                }}
                              >
                                Join In Teams
                              </Typography>
                            </Box>
                          </>
                        )}
                      </Box>
                    </Stack>
                    <Typography
                      variant="subtitle2"
                      style={{
                        color: theme.colors.neutral.darkGray,
                      }}
                    >
                      {"Business Area(s)"}
                    </Typography>
                    <Styled.ModalBodyMB2 variant="modalBody">
                      {event.businessAreaTags.join(", ")}
                    </Styled.ModalBodyMB2>
                    <Typography
                      variant="subtitle2"
                      style={{
                        color: theme.colors.neutral.darkGray,
                      }}
                    >
                      {"Group(s)"}
                    </Typography>
                    <Styled.ModalBodyMB2 variant="modalBody">
                      {event.groupTags.join(", ")}
                    </Styled.ModalBodyMB2>
                    <Typography
                      variant="subtitle2"
                      style={{
                        color: theme.colors.neutral.darkGray,
                      }}
                    >
                      {"Team(s)"}
                    </Typography>
                    <Styled.ModalBodyMB2 variant="modalBody">
                      {event.teamTags.join(", ")}
                    </Styled.ModalBodyMB2>

                    <Accordion
                      loading={signupsLoading || signups.length === 0}
                      title={
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Styled.SignUpsIcon />
                          {signupsLoading ? (
                            <Skeleton variant="text" style={{ width: 200 }} />
                          ) : (
                            <Typography variant="body2">{`${
                              signups.length
                            } Sign Up${
                              signups.length !== 1 ? "s" : ""
                            }`}</Typography>
                          )}
                        </Box>
                      }
                      rightComponent={
                        user.email === event.eventOrganizerEmail &&
                        !signupsLoading &&
                        signups.length > 0 &&
                        event.signUpSurvey && (
                          <Styled.SurveyResultsButtonContainer
                            onClick={() => {
                              setShowEventSignupsModal(true);
                            }}
                          >
                            <CategoryOutlinedIcon color="primary" />
                            <Typography
                              variant="body2"
                              style={{
                                marginLeft: theme.spacing(1),
                                marginRight: theme.spacing(1),
                              }}
                            >
                              Survey Results
                            </Typography>
                          </Styled.SurveyResultsButtonContainer>
                        )
                      }
                    >
                      {signups.map((signup: SignUp) => {
                        return (
                          <Contact
                            variant="modalBody"
                            size={24}
                            spacing={theme.spacing(1)}
                            name={signup.participantName}
                            key={signup.participantName}
                          />
                        );
                      })}
                    </Accordion>
                  </Box>
                </Styled.DetailsContainer>
                <Styled.LocationContainer>
                  <Typography
                    variant="modalSubtitle"
                    style={{
                      marginBottom: theme.spacing(2),
                    }}
                  >
                    Location
                  </Typography>
                  <Styled.MapContainer
                    loading="lazy"
                    src={`https://www.google.com/maps/embed/v1/place?key=${
                      process.env.REACT_APP_GOOGLE_API_KEY || ""
                    }
                    &q=${event.location}`}
                  ></Styled.MapContainer>
                  <Typography
                    variant="subtitle2"
                    style={{
                      color: theme.colors.neutral.darkGray,
                    }}
                  >
                    Event Location
                  </Typography>
                  <Typography
                    variant="modalBody"
                    style={{
                      marginBottom: theme.spacing(2),
                    }}
                  >
                    {event.location}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    style={{
                      color: theme.colors.neutral.darkGray,
                    }}
                  >
                    Transportation & Parking Details
                  </Typography>
                  <Styled.ModalBodyMB2 variant="modalBody">
                    {event.transportationParkingDetails || "N/A"}
                  </Styled.ModalBodyMB2>
                  <Typography
                    variant="modalSubtitle"
                    style={{
                      marginBottom: theme.spacing(2),
                    }}
                  >
                    Primary Contact(s)
                  </Typography>
                  {event.primaryContacts.map((name: string) => {
                    return (
                      <Contact
                        variant="modalBody"
                        size={24}
                        spacing={theme.spacing(1)}
                        name={name}
                        key={name}
                      />
                    );
                  })}
                </Styled.LocationContainer>
              </Stack>
            </Styled.ScrollableContent>
            {event.status === "CANCELLED" ? (
              <Alert severity="warning" sx={{ marginTop: theme.spacing(1) }}>
                This event was cancelled by the event organizer.
              </Alert>
            ) : (
              <Styled.ButtonsContainer>
                {signupsLoading || signupUserLoading || unsignupUserLoading ? (
                  <Styled.LoaderContainer>
                    <CircularProgress />
                  </Styled.LoaderContainer>
                ) : (
                  <Stack
                    direction={{
                      mobile: "column",
                      tablet: "row",
                      desktop: "row",
                    }}
                    width="100%"
                    spacing={2}
                  >
                    {user.email === event.eventOrganizerEmail && (
                      <Button
                        startIcon={<EditIcon />}
                        variant="outlined"
                        onClick={() => {
                          window.scroll(0, 0);
                          navigate(`/edit/${eventId}`);
                        }}
                        disabled={Date.now() > event.endDateTime * 1000}
                      >
                        Edit Event
                      </Button>
                    )}
                    {unixDateDiff(event.startDateTime, dateToUnix(new Date())) /
                      60 >=
                    15 ? (
                      isSignedUp ? (
                        <>
                          <Button
                            startIcon={<RemoveCircleOutlineOutlinedIcon />}
                            variant="outlined"
                            onClick={() => {
                              unsignUserToEvent();
                            }}
                          >
                            Unsign Up
                          </Button>
                        </>
                      ) : (
                        <Button
                          startIcon={<AddCircleOutlineOutlinedIcon />}
                          variant="outlined"
                          onClick={() => {
                            if (event && event.signUpSurvey?.length > 0) {
                              setShowSignUpSurveyModal(true);
                            } else {
                              signupUserToEvent();
                            }
                          }}
                        >
                          Sign Up
                        </Button>
                      )
                    ) : (
                      <Button
                        startIcon={<LoginOutlinedIcon />}
                        variant="contained"
                        onClick={() => {
                          navigate(`/live/${event.eventId}`);
                        }}
                        disabled={
                          unixDateDiff(
                            event.endDateTime,
                            dateToUnix(new Date())
                          ) /
                            60 <=
                          0
                        }
                      >
                        Join Live Event
                      </Button>
                    )}
                    {isSignedUp && (
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <CheckCircleRoundedIcon
                          color="success"
                          style={{ marginRight: theme.spacing(1) }}
                        />
                        <Typography variant="body2">Signed Up</Typography>
                      </Box>
                    )}
                  </Stack>
                )}
              </Styled.ButtonsContainer>
            )}
          </Stack>
        )}
      </BaseModal>
    );
  }
);

export default EventDetailsModal;
