import * as Styled from "./EventCard.styles";

import { CardContent, Typography } from "@mui/material";
import {
  getAmOrPm,
  getHourFromMilliseconds,
  getMinutesFromMilliseconds,
  getMonthAndDayFromMilliseconds,
  nthNumber,
} from "../../utils/utils";

import { EditEventIcon } from "./EventCard.styles";
import { Event } from "../../types/event";
import { GatherDefaultImage } from "../../assets";
import VideocamIcon from "@mui/icons-material/Videocam";
import { createSelector } from "@reduxjs/toolkit";
import { memo } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

type EventCardProps = {
  onClick?: any;
} & Event;

const EventCard = memo(
  ({
    eventId,
    status,
    title,
    description,
    eventHeroS3Url,
    startDateTime,
    endDateTime,
    onClick,
    eventOrganizerEmail,
    signUpCount,
  }: EventCardProps) => {
    const navigate = useNavigate();
    const currentTime = Date.now();
    const isEventExpired =
      endDateTime * 1000 < currentTime &&
      (status === "ACTIVE" || status === "COMPLETE");
    const isEventCancelled = status === "CANCELLED";

    const dateFormat = () => {
      const startMinutes = getMinutesFromMilliseconds(startDateTime);
      const endMinutes = getMinutesFromMilliseconds(endDateTime);
      const date = getMonthAndDayFromMilliseconds(startDateTime);

      return (
        <Typography variant="subtitle1">
          {getMonthAndDayFromMilliseconds(startDateTime)}
          {date !== "Today" && (
            <Styled.SuperScript>{nthNumber(startDateTime)}</Styled.SuperScript>
          )}{" "}
          {`${getHourFromMilliseconds(startDateTime)}${
            startMinutes !== "00" ? `:${startMinutes}` : ""
          }`}
          <Styled.SuperScript>{getAmOrPm(startDateTime)}</Styled.SuperScript>
          {" - "}
          {getHourFromMilliseconds(endDateTime)}
          {endMinutes !== "00" ? `:${endMinutes}` : ""}
          <Styled.SuperScript>{getAmOrPm(endDateTime)}</Styled.SuperScript>
        </Typography>
      );
    };

    const handleEditButtonClick = (event: any) => {
      event.stopPropagation();
      window.scroll(0, 0);
      navigate(`/edit/${eventId}`);
    };

    const userEmail = useSelector(
      createSelector(
        (state: any) => {
          return state.user.email;
        },
        (email: string) => {
          return email;
        }
      )
    );

    const renderLiveBanner = () => {
      if (
        startDateTime * 1000 <= currentTime &&
        currentTime <= endDateTime * 1000 &&
        status === "ACTIVE"
      ) {
        return (
          <Styled.LiveEventBannerWrapper>
            <Styled.LiveEventBanner>
              <VideocamIcon />
              <Typography variant="modalSubtitle"> Live</Typography>
            </Styled.LiveEventBanner>
          </Styled.LiveEventBannerWrapper>
        );
      }
      return null;
    };

    const renderExpired = () => {
      if (isEventExpired) {
        return (
          <Styled.LiveEventBannerWrapper>
            <Styled.LiveEventBanner>
              <Typography variant="modalSubtitle"> Completed </Typography>
            </Styled.LiveEventBanner>
          </Styled.LiveEventBannerWrapper>
        );
      }
      return null;
    };

    const renderCancelled = () => {
      if (isEventCancelled) {
        return (
          <Styled.LiveEventBannerWrapper>
            <Styled.LiveEventBanner>
              <Typography variant="modalSubtitle"> Cancelled </Typography>
            </Styled.LiveEventBanner>
          </Styled.LiveEventBannerWrapper>
        );
      }
      return null;
    };

    const isCurrentUserOrganizer = eventOrganizerEmail === userEmail;

    return (
      <Styled.EventCard
        onClick={onClick}
        style={{
          filter:
            isEventExpired || isEventCancelled ? "grayscale(100%)" : "none",
          pointerEvents: "auto",
        }}
      >
        <Styled.EventCardMediaContainer>
          <Styled.EventCardMedia image={eventHeroS3Url || GatherDefaultImage} />
          {isCurrentUserOrganizer && !isEventExpired && !isEventCancelled && (
            <Styled.EditButton
              onClick={handleEditButtonClick}
              className="editButton"
              startIcon={<EditEventIcon />}
            >
              Edit
            </Styled.EditButton>
          )}
          {renderExpired()}
          {renderLiveBanner()}
          {renderCancelled()}
        </Styled.EventCardMediaContainer>
        <CardContent>
          <Styled.EventDetailsRow direction="row">
            <Styled.DateColumn>
              <Styled.CalendarIcon />
              {dateFormat()}
            </Styled.DateColumn>
            <Styled.SignUpsColumn>
              <Styled.SignUpsIcon />
              <Typography variant="subtitle1">{`${signUpCount} Sign Up${
                signUpCount !== 1 ? "s" : ""
              }`}</Typography>
            </Styled.SignUpsColumn>
          </Styled.EventDetailsRow>
          <Styled.EventDetailsTitle variant="h3">
            {title}
          </Styled.EventDetailsTitle>
          <Styled.EventDescriptionContainer>
            <Styled.EventDescription variant="body2">
              {description}
            </Styled.EventDescription>
          </Styled.EventDescriptionContainer>
        </CardContent>
      </Styled.EventCard>
    );
  }
);

export default EventCard;
